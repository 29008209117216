.minimizable-web-chat > button.maximize {
  background-color: #EE7601;
  border-radius: 50%;
  border: 2px solid #EE7601;
  bottom: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  color: White;
  font-size: 30px;
  height: 64px;
  outline: 0;
  padding: 6px;
  padding-top: 10px;
  position: fixed;
  right: 20px;
  width: 64px;
  transition: all 0.2s ease;
}

.minimizable-web-chat > button.maximize:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.minimizable-web-chat > button.maximize > .red-dot {
  color: #0059aa;
  font-size: 12px;
  position: absolute;
  right: 12px;
  top: 49px;
}

.minimizable-web-chat > button.maximize:focus:not(:active),
.minimizable-web-chat > button.maximize:hover:not(:active) {
  background-color: #EE7601;
  border-color: #EE7601;
}

.minimizable-web-chat > button.maximize:active {
  color: #444;
}

.minimizable-web-chat > .chat-box {
  /* font-family: 'Open Sans', sans-serif !important; */
  background-color: rgba(255, 255, 255, 1);
  backdrop-filter: blur(2px);
  border-radius: 1px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
  bottom: 0px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 320px;
  position: fixed;
  width: 30%;
  height: 65%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-transition: -webkit-transform 0.3s ease;
  -moz-transition: -moz-transform 0.3s ease;
  -ms-transition: -ms-transform 0.3s ease;
  transition: transform 0.3s ease;
  -webkit-box-sizing: border-box;
  z-index: 9999;
}

@media only screen and (max-width: 600px) {
  .minimizable-web-chat > .chat-box {
    height: 100% !important;
    width: 100% !important;
    right: 0 !important;
    left: 0 !important;
    max-width: none !important;
    min-width: none !important;
  }
  .minimizable-web-chat .chat-box header button.switch {
    display: none;
  }
}

.minimizable-web-chat > .chat-box.show {
  -webkit-transform: translate(0, 0%);
  -moz-transform: translate(0, 0%);
  -ms-transform: translate(0, 0%);
  transform: translate(0, 0%);
}

.minimizable-web-chat > .chat-box.hide {
  -webkit-transform: translate(0, 100%);
  -moz-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
}

.minimizable-web-chat > .chat-box.left {
  left: 20px;
}

.minimizable-web-chat > .chat-box.right {
  right: 20px;
}

.minimizable-web-chat > .chat-box > header {
  background-color: #EE7601;
  height: 40px;
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0;
}

.minimizable-web-chat > .chat-box > header > .filler {
  flex: 0 10000 100%;
  padding-top: 10px;
  padding-left: 10px;
}

.minimizable-web-chat > .chat-box > header > .filler > span {
  color: white;
  font-family: Arial;
}

.minimizable-web-chat > .chat-box > header > button {
  width: 40px;
  height: 40px;
  background-color: Transparent;
  border: 0;
  color: White;
  outline: 0;
  margin: 0;
}

.minimizable-web-chat > .chat-box > header > button:focus:not(:active),
.minimizable-web-chat > .chat-box > header > button:hover:not(:active) {
  color: rgba(255, 255, 255, 0.6) !important;
}

.minimizable-web-chat > .chat-box > header > button:focus:active {
  color: rgba(126, 26, 26, 0.4) !important;
}

.minimizable-web-chat > .chat-box > .react-web-chat {
  flex: 1;
}

.minimizable-web-chat .webchat__bubble__content {
  border: 0 !important;
  background-color: #f4f4f4 !important;
  border-radius: 3px !important;
  animation: slide-up 0.7s ease;
}

@keyframes slide-up {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

.minimizable-web-chat .webchat__bubble__content .markdown {
  font-size: 13px !important;
  line-height: 18px !important;
}

.minimizable-web-chat .from-user > .webchat__bubble__content {
  background: rgb(11, 49, 77, 0.1) !important;
  font-size: 13px !important;
  border-radius: 3px !important;
  min-height: 0 !important;
 
}

.minimizable-web-chat .webchat__bubble--from-user p.plain {
  background: rgb(11, 49, 77, 0.1) !important;
  border-radius: 3px !important;
  min-height: 0px !important;
  line-height: 18px !important;
  font-size: 13px !important;
}

.minimizable-web-chat button.ac-pushButton {
  color: #444 !important;
  background-color: #eee !important;
  border-color: #ddd !important;
  border-radius: 3px !important;
  font-family: Arial !important;
  font-size: 13px !important;
  line-height: normal !important;
}

.minimizable-web-chat button.ac-pushButton:focus {
  border-color: #ccc !important;
}

.minimizable-web-chat button.ac-pushButton > div {
  white-space: normal !important;
}

.minimizable-web-chat .main {
  min-height: 60px !important;
  background-color: #f5f5f5 !important;
  padding: 5px 0 !important;
}

.minimizable-web-chat .main textarea {
  background-color: #fcfcfc !important;
  font-size: 14px !important;
  border: 1px solid #ddd !important;
  border-radius: 3px !important;
  padding: 5px !important;
  margin-top: -7px !important;
  width: 95% !important;
  -webkit-box-sizing: initial !important;
  -moz-box-sizing: initial !important;
  box-sizing: initial !important;
}

.minimizable-web-chat .ac-textBlock {
  white-space: normal !important;
  font-size: 14px !important;
}

.minimizable-web-chat .webchat__suggested-action__button {
  color: #444 !important;
  background-color: #eee !important;
  border-color: #ddd !important;
  border-radius: 3px !important;
  border-width: 1px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 14px !important;
}

.minimizable-web-chat .connect-spinner {
  font-family: Arial !important;
  font-size: 13px !important;
  color: #333  !important;
}

.ac-media-playButton-arrow {
  height: inherit !important;
  color: #333 !important;
}

.minimizable-web-chat .ac-input-container {
  font-size: 14px !important;
}

.minimizable-web-chat .ac-anchor {
  display: initial;
}

.minimizable-web-chat .ac-textBlock {
  margin: inherit;
}

.minimizable-web-chat .ac-textBlock p {
  letter-spacing: initial;
  text-transform: initial;
}
