@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,400&display=swap');

.crmbot  {
  margin: 0;
  padding: 0;
  /* font-family: "", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.crmbot * {
  font-family: Arial, sans-serif !important;
  font-size: 13px !important;
  -webkit-box-sizing: initial !important;
  -moz-box-sizing: initial !important;
  box-sizing: initial !important;
}

.crmbot code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
